import React, { useState } from 'react'
import { Link } from 'gatsby'

import Layout from '@components/Layout'
import Loader from '@components/custom/Loader'

const NotFound = () => {
  const [active, setActive] = useState()
  React.useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 1500)
  }, [])

  return (
    <Layout>
      <div className="h-96 py-10">
        {active ? (
          <>
            <h1>Page not found</h1>
            <Link className="text-blue-500 hover:text-blue-700" to="/">
              Head home
            </Link>{' '}
          </>
        ) : (
          <div className="flex items-center justify-center h-40 w-full mx-auto">
            <Loader />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default NotFound
